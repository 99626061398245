import { Horse, User } from "@/openapi";
import { _ } from "@/pages/_app";
import { IStore } from "@/redux/store";
import { Locales, getLocale, setLocale } from "@/utils/translation";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { connect } from "react-redux";
import DropdownButton from "./buttons/DropdownButton";
import HorseDayText from '@/public/svg/horseday_logo_text.svg';
import HorseDayLogo from '@/public/svg/horseday_logo.svg';

interface ICheckoutNavbarProps {
  user: User | undefined;
  onLangChange: () => void;
}

function localeToReadable(locale: Locales) {
  const localeMap = {
    'en_GB': 'EN',
    'de': 'DE',
    'is': 'IS'
  };

  return localeMap[locale] || 'Unknown';
}

const CheckoutNavbar = (props: ICheckoutNavbarProps) => {
  const router = useRouter();

  const [locale, _setLocale] = useState(getLocale());

  const renderUserImage = () => {
    const userImage = props.user?.aThumbnail;
    if (!userImage) {
      return (
        <AccountCircleIcon
          style={{ height: 32, width: 32, color: "gray", border: "none" }}
        />
      );
    }
    return (
      <Image
        className="w-8 h-8 rounded-full"
        alt="user photo"
        src={userImage!}
        width={32}
        height={32}
      />
    );
  };

  return (
    <nav
      className="bg-primary-brown py-2.5 w-full shadow-md flex "
      style={{ height: 64 }}
    >
      <div style={{ maxWidth: 1000 }} className="flex flex-row justify-between w-full mx-auto px-6  ">
        <div className="flex-row flex">
          <a href="https://www.horseday.com/" className="flex items-center" rel="noopener noreferrer" target="_blank">
            <HorseDayLogo />
            <div className="hidden sm:block ml-4">
              <HorseDayText />
            </div>
          </a>

        </div>
        <div className="flex flex-row ">
          <DropdownButton<Locales>
            className="text-sm  rounded-full ml-1 "
            title={""}
            items={[
              { title: "EN", value: "en_GB" },
              { title: "DE", value: "de" },
              { title: "IS", value: "is" },
            ]}
            selected={locale}
            onChange={async (v) => {
              _setLocale(v);
              await setLocale(v);
              props.onLangChange();
            }}
            child={
              <div className="text-white text-base rounded-lg border p-2 pl-3 pr-3 border-white sm:mr-8 mr-4 cursor-points">
                {localeToReadable(locale)}
              </div>
            }
          />

          <button type="button" className="text-sm  rounded-full ">
            {props.user ? (
              <Link href="/settings">{renderUserImage()}</Link>
            ) : (
              <Link
                href="/login"
                className="text-white text-base rounded-lg border p-2 pl-3 pr-3 border-white cursor-points"
              >
                {_("Sign up")}
              </Link>
            )}
          </button>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: IStore) => {
  const { user } = state.userState || undefined;

  return { user };
};

export default connect(mapStateToProps)(CheckoutNavbar);
